.juicer-feed.modern {
    font-family: $aui-font-extended !important;

    .feed-item {
        border: 1px solid rgba(0,0,0,0.5) !important;
        padding: 10px !important;

        .j-text {
            padding: 10px !important;
            font-size: 12px !important;
            color: $aui-color-body !important;
        }

        .j-message {
            color: $aui-color-body !important;
        }
    }

    a:hover {
        color: $aui-color-gray30 !important;
    }

    .j-paginate {
        text-transform: none !important;
        border: 1px solid $aui-color-black !important;
        color: $aui-color-black;
        background: $aui-color-white !important;

        &:hover {
            border-color: $aui-color-gray30 !important;
            color: $aui-color-gray30 !important;
        }
    }
}

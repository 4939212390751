// Base styles
// $aui-breakpoint: ( tiny : 480px, xsmall : 600px, small : 740px, medium : 960px, large : 1264px, xlarge : 1424px, huge : 1584px, ) !default;
$aui-breakpoint: (
    tiny: 480px,
    xsmall: 600px,
    small: 740px,
    medium: 960px,
    large: 1264px,
);
$aui-modular-scale: (
    default: (base: 13px, ratio: 1.14,),
    small: (ratio: 1.17,),
    large: (base: 14px, ratio: 1.21,),
);
$font-path: "https://cdn01.audi.com.au/fonts";

@import "../audi-ui-components/styles/main";
@import "aui-overrides";
// override bootstrap breakpoints with aui breakpoints
$grid-breakpoints: map-merge((zero: 0), $aui-breakpoint);
@import "bootstrap";
// responsive bootstrap gutters
$gutter-tiny: 10px;
$gutter-small: 15px;
$gutter-medium: 18px;

.container, .container-fluid {
    padding-left: $gutter-tiny;
    padding-right: $gutter-tiny;
}
.row {
    margin-right: -($gutter-tiny);
    margin-left: -($gutter-tiny);
}
.row > .col, .row > [class*="col-"] {
    padding-right: $gutter-tiny;
    padding-left: $gutter-tiny;
}
.container-outer {
    padding-left: $gutter-tiny * 2;
    padding-right: $gutter-tiny * 2;
}
.row.double-gutter {
    margin-right: -($gutter-tiny * 2);
    margin-left: -($gutter-tiny * 2);
}
.row.double-gutter > .col, .row.double-gutter > [class*="col-"] {
    padding-right: ($gutter-tiny * 2);
    padding-left: ($gutter-tiny * 2);
}
@media (min-width: map-get($grid-breakpoints, 'small')) {
    .container, .container-fluid {
        padding-left: $gutter-small;
        padding-right: $gutter-small;
    }
    .row {
        margin-right: -($gutter-small);
        margin-left: -($gutter-small);
    }
    .row > .col, .row > [class*="col-"] {
        padding-right: $gutter-small;
        padding-left: $gutter-small;
    }
    .container-outer {
        padding-left: $gutter-small * 2;
        padding-right: $gutter-small * 2;
    }
    .row.double-gutter {
        margin-right: -($gutter-small * 2);
        margin-left: -($gutter-small * 2);
    }
    .row.double-gutter > .col, .row.double-gutter > [class*="col-"] {
        padding-right: ($gutter-small * 2);
        padding-left: ($gutter-small * 2);
    }
}
@media (min-width: map-get($grid-breakpoints, 'medium')) {
    .container, .container-fluid {
        padding-left: $gutter-medium;
        padding-right: $gutter-medium;
    }
    .row {
        margin-right: -($gutter-medium);
        margin-left: -($gutter-medium);
    }
    .row > .col, .row > [class*="col-"] {
        padding-right: $gutter-medium;
        padding-left: $gutter-medium;
    }
    .container-outer {
        padding-left: $gutter-medium * 2;
        padding-right: $gutter-medium * 2;
    }
    .row.double-gutter {
        margin-right: -($gutter-medium * 2);
        margin-left: -($gutter-medium * 2);
    }
    .row.double-gutter > .col, .row.double-gutter > [class*="col-"] {
        padding-right: ($gutter-medium * 2);
        padding-left: ($gutter-medium * 2);
    }
}
$header-height: 50px;
$header-height-small: 60px;
$header-height-medium: 66px;
$header-height-large: 145px;

body {
    background: $aui-color-gray95;
}

img,
video {
    max-width: 100%;
    display: block;
}

b,
strong {
    font-weight: bold;
}

.outer-wrapper {
    position: relative;
    background: $aui-color-white;
    width: 100%;
    max-width: 1421px;
    margin: 0 auto;
}

.vertical-gutter {
    padding-top: $gutter-small;
    padding-bottom: $gutter-small;
    @media (min-width: map-get($grid-breakpoints, 'medium')) {
        padding-top: $gutter-medium;
        padding-bottom: $gutter-medium;
    }
}

.text-input {
    width: 100%;
    border: 1px solid;
    box-shadow: none;
    outline: none !important;
    padding: 0.5em 1.2em;
    font-size: 0.9375rem;
    line-height: 1.5rem;

    &:focus {
        border-color: $aui-color-red;
    }
}

.audiicon-responsive {
    .audiicon-large,
    .aui-button--icon--large {
        display: none;
    }
    @media (min-width: map-get($aui-breakpoint, 'xsmall')) {
        .audiicon-small,
        .aui-button--icon {
            display: none;
        }

        .audiicon-large,
        .aui-button--icon--large {
            display: inline-block;
        }
    }
}

.load-more-wrapper,
.spinner-wrapper {
    width: 100%;
    text-align: center;
    padding: 40px;
}

.spinner-container {
    position: relative;
    min-height: 120px;
}

.spinner-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $aui-color-white;
    transition: opacity 1s;
    z-index: 80;

    &.spiner-exit {
        opacity: 1;
    }

    &.spinner-exit-active {
        opacity: 0;
    }

    &.spinner-exit-done {
        display: none;
    }
}

.video-wrapper--background {
    background: $aui-color-black;

    video {
        width: 100%;
    }
}

.video-wrapper--width {
    max-width: 125vh;
    margin: 0 auto;

    .video-wrapper--height {
        width: 100%;
        height: 0;
        position: relative;
        padding: 56.25% 0 0 !important;

        iframe {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.cms-content {
    p {
        text-align: justify;
        margin: 0 0 1.8em;
    }

    p:last-child {
        margin: 0;
    }
}

.cms-content a,
.text-link {
    color: $aui-color-black;
    text-decoration: underline;

    &:hover {
        color: $aui-color-gray30;
    }
}

hr {
    padding: 0;
    margin: 0;
    height: 0;
    border: solid #202020; //$aui-color-gray20;
    border-width: 1px 0 0;
    opacity: 0.5;

    &.light {
        border-color: #d2d2d2;
    }

    &.separator {
        margin-top: $gutter-small;
        margin-bottom: $gutter-small;
        @media (min-width: map-get($aui-breakpoint, 'medium')) {
            margin-top: $gutter-medium;
            margin-bottom: $gutter-medium;
        }
    }

    &.separator-top {
        margin-top: $gutter-small;
        @media (min-width: map-get($aui-breakpoint, 'medium')) {
            margin-top: $gutter-medium;
        }
    }

    &.separator-bottom {
        margin-bottom: $gutter-small;
        @media (min-width: map-get($aui-breakpoint, 'medium')) {
            margin-bottom: $gutter-medium;
        }
    }

    &.fullbleed-below-small {
        @media (max-width: map-get($aui-breakpoint, 'small') - 1) {
            margin-left: -($gutter-tiny * 2);
            margin-right: -($gutter-tiny * 2);
        }
    }

    &.fullbleed-below-medium {
        @media (max-width: map-get($aui-breakpoint, 'medium') - 1) {
            margin-left: -($gutter-small * 2);
            margin-right: -($gutter-small * 2);
        }
        @media (max-width: map-get($aui-breakpoint, 'small') - 1) {
            margin-left: -($gutter-tiny * 2);
            margin-right: -($gutter-tiny * 2);
        }
    }
}
@import "header";
@import "panel";

#main {
    .main-wrapper {
        padding-top: $header-height;
        padding-bottom: 100px;
        @media (min-width: map-get($aui-breakpoint, 'xsmall')) {
            padding-bottom: 150px;
        }
        @media (min-width: map-get($aui-breakpoint, 'small')) {
            padding-top: $header-height-small;
        }
        @media (min-width: map-get($aui-breakpoint, 'medium')) {
            padding-top: $header-height-medium;
        }
        @media (min-width: map-get($aui-breakpoint, 'large')) {
            padding-top: $header-height-large;
            padding-bottom: $gutter-medium * 2;
        }
    }

    .main-column {
        width: 100%;
        @media (min-width: map-get($aui-breakpoint, 'large')) {
            width: calc(100% - 336px);
        }
    }

    .sidebar {
        width: 300px;
    }

    .sidebar-block {
        margin-top: $gutter-medium;
        padding: 14px 0 0;

        &.first {
            margin-top: 0;
        }
    }

    .page-title {
        margin: 0 0 $gutter-small;
        @media (min-width: map-get($aui-breakpoint, 'medium')) {
            margin: 0 0 $gutter-medium;
        }

        &.padded {
            padding-top: $gutter-small;
            @media (min-width: map-get($aui-breakpoint, 'medium')) {
                padding-top: $gutter-medium;
            }
        }
    }
}
@import "footer";
@import "search";
@import "article-preview";
@import "pages";

.search-results {
    padding: 0 0 (40px * 2);

    .search-row {
        padding-top: 40px;
        padding-bottom: 40px;

        .search-field-wrapper {
            width: calc(100% - 160px);
            @media (min-width: map-get($aui-breakpoint, 'small')) {
                max-width: 800px;
            }
        }
    }
}

.subscribe-form {
    .subscribe-form-title {
        margin: 0 0 0.5em;
        font-weight: bold;
    }

    .subscribe-form-copy {
        margin: 0 0 1em;
        font-family: $aui-font-extended;
        font-weight: bold;
        font-size: 13px;
    }

    input {
        margin: 0 0 10px;
        border-color: rgba(210, 210, 210, 0.5);
    }

    .data-collection {
        max-height: 0;
        overflow: hidden;
        transition: max-height 1s;
        font-size: 12px;
        line-height: 18px;

        &.active {
            max-height: 400px;
        }
    }

    .data-collection-toggle {
        .aui-button__text::after {
            transform: rotate(90deg);
        }

        &:hover {
            .aui-button__text::after {
                transform: rotate(90deg);
            }
        }

        &.active {
            .aui-button__text::after {
                transform: rotate(-90deg);
            }

            &:hover {
                .aui-button__text::after {
                    transform: rotate(-90deg);
                }
            }
        }
    }
}

.page-content--subscribe .subscribe-form {
    .subscribe-form-title {
        margin: 0 0 2em;
        padding: 15px 0 0;
    }

    .subscribe-form-copy {
        margin: 0 0 2em;
    }

    .data-collection {
        max-height: none;
        margin-top: 50px;
    }

    .data-collection-toggle {
        display: none;
    }
}

.share-widget {
    position: relative;
    display: inline-block;

    .audiicon {
        vertical-align: middle;
    }

    .share-widget__expand-btn {
        border: none;
        border-radius: 20px;
        background: $aui-color-white;
        box-shadow: none;
        height: 40px;
        line-height: 40px;
        padding: 0 10px;
        position: relative;
        z-index: 50;
        font-weight: bold;
        font-size: 12px;
        outline: none;
        cursor: pointer;

        .audiicon {
            width: 20px;
        }
    }

    .share-widget__panel {
        position: absolute;
        top: 2px;
        right: calc(100% - 20px);
        height: 36px;
        overflow-x: hidden;
        max-width: 0;
        transition: max-width 0.5s;
        z-index: 1;
    }

    .share-widget__buttons {
        white-space: nowrap;
        line-height: 36px;
        background: $aui-color-gray90;
        padding: 0 20px 0 7px;
        border-radius: 20px 0 0 20px;

        .SocialMediaShareButton {
            display: inline-block;
            margin-right: 10px;
            outline: none;
            cursor: pointer;
        }
    }

    &.expanded {
        .share-widget__panel {
            max-width: 250px;
        }
    }
}

.social-list {
    list-style: none;
    padding: 0;
    margin: $gutter-small 0;
    @media (min-width: map-get($aui-breakpoint, 'medium')) {
        margin: $gutter-medium 0;
    }

    li {
        padding: 5px 0;
    }
}

.social-link {
    .audiicon {
        vertical-align: middle;
    }

    .social-link__url {
        margin-left: 10px;
    }
}

.filters-list {
    .filter {
        position: relative;
        font-weight: bold;
        margin-left: 5px;

        &::after {
            content: ",";
        }

        &:last-child::after {
            content: "";
            padding-right: 5px;
        }

        .audiicon {
            display: inline-block;
            width: 15px;
            height: 15px;
            position: absolute;
            top: -7px;
            right: -7px;
        }
    }

    a.filter {
        &:hover {
            color: $aui-color-red;

            &::after {
                color: $aui-color-black;
            }
        }
    }
}
@import "juicer";

.media-figure {
    margin: 0;
    position: relative;
    overflow: hidden;

    figcaption {
        background: $aui-color-gray50;
        color: $aui-color-white;
        position: absolute;
        bottom: 0;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s;
    }

    &.hover,
    &:hover {
        figcaption {
            max-height: 100px;
        }
    }

    &.touch.not-hover {
        // because :hover state doesn't go away on touch device until another element gets focus

        figcaption {
            max-height: 0;
        }
    }

    .caption {
        display: inline-block;
        padding: 3px;
    }
}

.media-image {
    padding-top: 42%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.lightbox-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: rgba(0,0,0,0.5);
    padding: 3px;
    box-shadow: none;
    color: $aui-color-white;
    cursor: pointer;
    svg {
        display: block;
        width: 24px;
        height: 24px;
    }
}

/*
 * sections
 */
.section {
    padding-top: ($gutter-small * 2);
    padding-bottom: ($gutter-small * 2);
    @media (min-width: map-get($aui-breakpoint, 'medium')) {
        padding-top: ($gutter-medium * 2);
        padding-bottom: ($gutter-medium * 2);
    }

    &.indent {
        @media (min-width: map-get($aui-breakpoint, 'medium')) {
            margin: 0 ($gutter-medium * 2);
        }
    }

    &.outdent {
        margin: 0 (-$gutter-tiny * 2);
        @media (min-width: map-get($aui-breakpoint, 'small')) {
            margin: 0 (-$gutter-small * 2);
        }
        @media (min-width: map-get($aui-breakpoint, 'medium')) {
            margin: 0 auto;
        }
    }

    &.related {
        padding-top: 30px;
        padding-bottom: 10px;
    }

    &.subscribe {
        padding-top: 50px;
        padding-bottom: 20px;
    }

    &.first {
        padding-top: 0;
    }
    @media (min-width: map-get($aui-breakpoint, 'small')) {
        &.double .media-image {
            padding-top: 45%;
        }

        &.multi .media-image {
            padding-top: 68%;
        }
    }

    .quote {
        font-family: $aui-font-extended;
        font-weight: bold;
        font-size: 14px;
        width: 100%;

        .quote-inner {
            margin: 0 auto;
            width: 80%;
            padding: 0 0 20px;
            border-bottom: 1px solid rgba(32,32,32,0.5);

            p {
                margin: 0;
                text-align: left;
            }
        }
        @media (min-width: map-get($aui-breakpoint, 'small')) {
            .quote-inner {
                width: 60%;
            }
        }
        @media (min-width: map-get($aui-breakpoint, 'medium')) {
            font-size: 16.1px;
        }
    }
}

.section.sectionGallery {
    .row {
        margin-right: -12px;
        margin-left: -12px;
    }

    .row > .col,
    .row > [class*="col-"] {
        padding-right: 12px;
        padding-left: 12px;
    }

    .media-image {
        margin-bottom: 24px;
    }

    &.sectionGallery--carousel .media-image {
        margin-bottom: 0;
    }
    @media (min-width: map-get($aui-breakpoint, 'small')) {
        .col-small-4 .media-image {
            padding-top: 68%;
        }

        .col-small-6 .media-image {
            padding-top: 45%;
        }
    }
}

.section.sectionCTA {
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-content: center;
    flex-wrap: wrap;
    .cta-item {
        position: relative;
        flex: 1 1 50%;
        max-width: 800px;
        .cta-item__text {
            font-weight: bold;
        }
        &.has-media {
            .cta-item__text {
                position: absolute;
                inset: 0;
                padding: 1rem;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                color: $aui-color-light;
                background: linear-gradient(200deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 90%);
            }
        }
    }
    @media (min-width: map-get($aui-breakpoint, 'medium')) {
        flex-wrap: nowrap;
    }
}

/*
 * all pages
 */
.page-content {
    position: relative;
    min-height: 50vh;

    .page-title {
        font-family: $aui-font-extended;
        font-weight: bold;
        font-size: 16px;
        line-height: normal;
        @media (min-width: map-get($aui-breakpoint, 'medium')) {
            font-size: 50px;
        }
    }

    .subtitle {
        font-weight: normal;
        @media (min-width: map-get($aui-breakpoint, 'medium')) {
            font-family: $aui-font-extended;
            font-size: 15px;
            font-weight: bold;
        }
    }

    .hero-image {
        position: relative;

        &:hover .media-figure figcaption {
            max-height: 100px;
        }

        .image {
            height: 0;
            padding-top: 42%;
            background-size: cover;
            background-repeat: no-repeat;
        }

        .text {
            pointer-events: none;
            width: 100%;
            padding: $gutter-tiny ($gutter-tiny * 2);
            z-index: 70;
            color: $aui-color-body;
            font-weight: bold;
            @media (min-width: map-get($aui-breakpoint, 'small')) {
                padding: $gutter-small ($gutter-small * 2);
            }
            @media (min-width: map-get($aui-breakpoint, 'medium')) {
                color: inherit;
                position: absolute;
                top: 0;
                left: 0;
                padding: $gutter-medium ($gutter-medium * 2);
                height: 100%;
                background: linear-gradient(160deg, rgba(0,0,0,0.45) 0%, rgba(0,0,0,0) 50%);
            }

            &.black {
                @media (min-width: map-get($aui-breakpoint, 'medium')) {
                    background: linear-gradient(160deg, rgba(255,255,255,0.45) 0%, rgba(255,255,255,0) 50%);
                }
            }
        }

        img {
            display: block;
            width: 100%;
            z-index: 60;
        }
    }

    .sectionIntro {
        .share-widget {
            float: right;
        }

        .intro {
            font-family: $aui-font-extended;
            font-weight: bold;
            margin-bottom: $gutter-small;
            @media (min-width: map-get($aui-breakpoint, 'medium')) {
                font-size: 15.5px;
                margin-bottom: $gutter-medium;
            }
        }

        .info {
            p {
                font-weight: bold;
                margin: 0;
                font-size: 90%;

                &.date,
                label {
                    font-weight: normal;
                }
            }
        }
    }

    .sectionQuote {
        .quote {
            .quote-inner {
                margin-top: 30px;
                margin-bottom: 30px;
            }
            @media (min-width: map-get($aui-breakpoint, 'small')) {
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
            }
        }
    }

    .slide-article-img img {
        width: 100%;
        display: block;
    }
}
/*
 * article pages
 */
.page-content--article {
    article .section {
        &:first-of-type {
            padding: 0;
        }

        &:last-of-type {
            padding-bottom: 130px;
        }
    }
}
/*
 * author pages
 */
.page-content--author {
    .title-wrapper {
        margin: 0 0 30px;
    }

    .page-title {
        font-family: $aui-font-extended;
        font-weight: bold;
        font-size: 16px;
        line-height: normal;
        margin: 0 0 10px;
        @media (min-width: map-get($aui-breakpoint, 'medium')) {
            font-size: 40px;
        }
    }

    .subtitle {
        font-weight: normal;
        @media (min-width: map-get($aui-breakpoint, 'medium')) {
            font-family: $aui-font-extended;
            font-size: 16px;
            font-weight: bold;
        }
    }

    .author__img-col {
        @media (min-width: map-get($aui-breakpoint, 'small')) {
            width: 46.79%;
        }
    }

    .author__bio-col {
        @media (min-width: map-get($aui-breakpoint, 'small')) {
            width: 47.65%;

            .section {
                margin-left: 28px;
            }
        }
    }
}

/*
 * article list
 */
.articles-list {
    .list-item {
        transition-property: transform, opacity;
        transition-duration: 0.5s;
        transition-delay: 0s; // increasing transition-delay set by JS
        transition-timing-function: ease-out;

        &.li-appear,
        &.li-enter {
            opacity: 0;
            transform: translateY(10%);
        }

        &.li-appear-active,
        &.li-enter-active {
            opacity: 1;
            transform: translateY(0);
        }

        &.li-enter-done {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .list-item__category {
        color: $aui-color-red;
        font-size: 85%;
        font-weight: bold;
        font-family: $aui-font-extended;
    }

    .list-item__date {
        color: $aui-color-gray60;
        font-size: 70%;
        font-weight: bold;
        font-family: $aui-font-extended;
    }

    .list-item__tags {
        .tag {
            display: inline-block;
            white-space: nowrap;
            margin-right: 10px;
            text-decoration: underline;
        }
    }
}

.articles-list-ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.articles-list-row,
.articles-list-ul {
    .list-item {
        padding: $gutter-tiny 0;
        @media (min-width: map-get($aui-breakpoint, 'small')) {
            padding: $gutter-small 0;
        }
        @media (min-width: map-get($aui-breakpoint, 'medium')) {
            padding: $gutter-medium 0;
        }
        &::after {
            content: ' ';
            display: block;
            clear: both;
        }
    }

    .list-item__image {
        width: 50%;
        float: left;
        background-size: cover;
        background-position: center;
        position: relative;
        span {
            display: block;
            height: 0;
            width: 100%;
            padding-top: 65%;
        }
    }

    .list-item__text {
        line-height: 1.2em;
        margin-left: 55%;
        .list-item__title {
            font-weight: bold;
            font-size: 14px;
            line-height: normal;
            margin: 0 0 7px;
            @media (min-width: map-get($aui-breakpoint, 'large')) {
                font-size: 15px;
            }
        }
    }

    &.small {
        .list-item__image {
            width: 51px;
            height: 51px;
            span {
                display: none;
            }
        }

        .list-item__text {
            margin-left: 64px;
            .list-item__title {
                font-size: 12px;
                line-height: 16px;
                margin: 0 0 4px;
            }
        }
    }
}

.list-item__image,
.tile__image-wrapper {
    position: relative;

    .audiicon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        background: radial-gradient(circle, rgba(0,0,0,0.15) 0%, rgba(0,0,0,0) 50%);
        width: 20%;
        height: 20%;
        min-width: 40px;
        min-height: 40px;
        max-width: 80px;
        max-height: 80px;
        path {
            stroke-width: 2px;
        }
    }
}
/*
* article tile
*/
.tile {
    display: block;
    position: relative;
    margin: 0 0 ($gutter-tiny * 2);
    @media (min-width: map-get($aui-breakpoint, 'small')) {
        margin: 0 0 ($gutter-small * 2);
    }
    @media (min-width: map-get($aui-breakpoint, 'medium')) {
        margin: 0 0 ($gutter-medium * 2);
    }

    .bg-image-load {
        position: absolute;
        left: 100%;
        width: 1px;
        height: 1px;
    }

    .tile__image-wrapper {
        position: relative;
        height: 0;
        padding-top: 70.4%;
        overflow: hidden;
    }

    .tile__image {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        background-size: cover;
        background-position: center;
        transition: width 2s, height 2s;
        z-index: 10;
    }

    .tile__text {
        font-family: $aui-font-extended;
        color: $aui-color-black;
        width: 100%;
        padding: $gutter-tiny 0 0;
        z-index: 20;
        @media (min-width: map-get($aui-breakpoint, 'small')) {
            padding: $gutter-small 0 0;
        }
        @media (min-width: map-get($aui-breakpoint, 'medium')) {
            color: inherit;
            position: absolute;
            top: 0;
            left: 0;
            padding: 22px 30% 22px 22px;
            height: 100%;
            background: linear-gradient(160deg, rgba(0,0,0,0.45) 0%, rgba(0,0,0,0) 50%);
        }

        &.black {
            @media (min-width: map-get($aui-breakpoint, 'medium')) {
                background: linear-gradient(160deg, rgba(255,255,255,0.45) 0%, rgba(255,255,255,0) 50%);
            }
        }
    }

    .tile__title {
        font-weight: bold;
        font-size: 14px;
        line-height: normal;
        margin: 0 0 5px;
        @media (min-width: map-get($aui-breakpoint, 'medium')) {
            font-size: 18px;
        }
    }

    .tile__summary {
        font-size: 12px;
        line-height: normal;
        @media (min-width: map-get($aui-breakpoint, 'medium')) {
            font-size: 10px;
        }
    }
}
/*
* slider
*/
.slider-wrapper {
    .slider-list {
        transition: height 0.5s ease-out;
    }

    .tile {
        margin: 0;
    }

    .slider-control-bottomcenter {
        position: absolute;
        bottom: 10px !important;
        left: 0 !important;
        width: 100%;
        transform: none !important;
    }

    .slider-dots {
        padding: 0 20px;
    }

    .slider-dots button {
        border: none;
        box-shadow: none;
        outline: none;
        background: transparent;
        color: inherit;
        height: $gutter-small * 2;
        @media (min-width: map-get($aui-breakpoint, 'medium')) {
            height: $gutter-medium * 2;
        }
        padding: 0 5px;
        cursor: pointer;

        &.active {
            color: $aui-color-red;
        }
    }

    .slider-control-centerleft,
    .slider-control-centerright {
        button {
            background: transparent;
            border: none;
            color: $aui-color-white;
            outline: none;
            cursor: pointer;
        }
    }
}
/*
* home page slider
*/
.hp-carousel {
    position: relative;
    margin: 0 (-$gutter-tiny * 2);
    overflow: hidden;
    @media (min-width: map-get($aui-breakpoint, 'small')) {
        margin: 0 (-$gutter-small * 2);
    }
    @media (min-width: map-get($aui-breakpoint, 'medium')) {
        margin: 0;
    }

    .first-slide {
        position: absolute;
        width: 100%;
        height: 100%;
        margin: 0;
        z-index: 1;
    }

    .tile {
        .tile__image-wrapper {
            padding-top: 53.1%;
        }

        .tile__text {
            padding-left: ($gutter-tiny * 2);
            padding-right: ($gutter-tiny * 2);
            @media (max-width: map-get($aui-breakpoint, 'medium') - 1) {
                background: $aui-color-white;
            }
            @media (min-width: map-get($aui-breakpoint, 'small')) {
                padding-left: ($gutter-small * 2);
                padding-right: ($gutter-small * 2);
            }
            @media (min-width: map-get($aui-breakpoint, 'medium')) {
                padding-right: 53%;
            }
        }

        .tile__title {
            font-size: 14px;
            @media (min-width: map-get($aui-breakpoint, 'medium')) {
                font-size: 30px;
            }
        }

        .tile__summary {
            font-size: 12px;
            @media (min-width: map-get($aui-breakpoint, 'medium')) {
                font-size: 13px;
            }
        }
    }

    .spinner-wrapper {
        background: $aui-color-white;
        z-index: 20;
    }

    .slider-wrapper {
        height: 100%;
        width: 100%;
        z-index: 10;
    }

    .slider-container {
        margin: 0 0 ($gutter-medium * 2);
        z-index: 10;
    }

    .slider-container,
    .slider-frame {
        height: 100% !important;
    }

    .slider-control-bottomcenter {
        bottom: auto !important;
        top: 100% !important;
        padding: 0 ($gutter-tiny * 2);
        @media (min-width: map-get($aui-breakpoint, 'small')) {
            padding: 0 ($gutter-small * 2);
        }
        @media (min-width: map-get($aui-breakpoint, 'medium')) {
            padding: 0;
        }
    }

    .slider-dots {
        padding: 0;
    }

    .slider-dots button {
        background: $aui-color-white;
        color: $aui-color-gray70;
        padding: 0 10px 0 0;
    }

    .slider-dots__next {
        font-family: $aui-font-extended;
        font-size: 10px;
        color: $aui-color-gray70;
        float: right;
        max-width: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: ($gutter-small * 2);
        @media (min-width: map-get($aui-breakpoint, 'medium')) {
            line-height: ($gutter-medium * 2);
        }
    }
}
/*
* home page secondary features
*/
.hp-features {
    .tile__image-wrapper {
        padding-top: 56.85%;
    }

    .hp-features__main {
        margin: 0 0 $gutter-small;
        @media (min-width: map-get($aui-breakpoint, 'medium')) {
            margin: 0;
        }
    }

    .hp-features__list {
        flex-wrap: wrap;
        margin: 0 (-($gutter-tiny));
        @media (min-width: map-get($aui-breakpoint, 'small')) {
            display: flex;
            margin: 0 (-($gutter-small));
        }
        @media (min-width: map-get($aui-breakpoint, 'medium')) {
            margin: (-($gutter-medium)) 0;
            height: calc(100% + #{$gutter-medium * 2});
            flex-direction: column;
            flex-wrap: nowrap;
        }

        .list-item {
            flex: 0 1 33.3%;
            @media (min-width: map-get($aui-breakpoint, 'small')) {
                padding: $gutter-small;
            }
            @media (min-width: map-get($aui-breakpoint, 'medium')) {
                flex: 1 1 33.3%;
                padding: $gutter-medium 0;
            }
        }

        .list-item__image {
            width: 100%;
            margin: 0 0 20px;
            @media (min-width: map-get($aui-breakpoint, 'medium')) {
                width: 50%;
                height: 100%;
                padding: 0;
                margin: 0;
                span {
                    display: none;
                }
            }
        }

        .list-item__text {
            line-height: 1.2em;
            margin-left: 0;
            @media (min-width: map-get($aui-breakpoint, 'medium')) {
                margin-left: 55%;
            }

            .feature__title {
                font-size: 16px;
                font-weight: bold;
            }
        }
    }
}
/*
* sidebar
*/
.sidebar .articles-list-ul .list-item {
    border-bottom: 1px solid rgba(210,210,210,0.5);
    padding: 24px 0;

    .list-item__image .audiicon {
        display: none;
    }

    .list-item__text {
        margin: 0 64px;
    }
}
/*
* search results
*/
.search-results .articles-list {
    .list-item__image {
        max-width: 140px;
    }

    .list-item__text {
        margin-left: 150px;
    }
}
/*
* related articles
*/
.related {
    .related--title {
        font-weight: bold;
        margin: 0;
    }
}

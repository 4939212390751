.search-form {
    .search-input-wrapper {
        margin: 0 0 $gutter-medium;
    }

    .text-input {
        padding: 15px 20px;
        font-size: 14px;
        font-family: $aui-font-extended;
        font-weight: bold;
        @media (min-width: map-get($aui-breakpoint, 'small')) {
            font-size: 16px;
        }
        @media (min-width: map-get($aui-breakpoint, 'medium')) {
            font-size: 20px;
        }
    }

    .search-heading {
        font-family: $aui-font-extended;
        font-weight: bold;
        font-size: 14px;
        margin: 0 0 20px;
        @media (min-width: map-get($aui-breakpoint, 'small')) {
            font-size: 16px;
        }
        @media (min-width: map-get($aui-breakpoint, 'medium')) {
            font-size: 20px;
        }
    }

    .search-subheading {
        font-family: $aui-font-extended;
        font-weight: bold;
        font-size: 15px;
        margin: 0 0 10px;
    }

    .search-filter {
        margin: 0 0 10px;
    }

    .articles-list {
        .list-item__image {
            width: 100%;
            margin: 0 0 5px;
            @media (min-width: map-get($aui-breakpoint, 'small')) {
                width: 44%;
                margin: 0;
            }
            @media (min-width: map-get($aui-breakpoint, 'medium')) {
                width: 280px;
            }
        }

        .list-item__text {
            margin-left: 0;
            @media (min-width: map-get($aui-breakpoint, 'small')) {
                margin-left: 48%;
            }
            @media (min-width: map-get($aui-breakpoint, 'medium')) {
                margin-left: 300px;
            }
        }

        .list-item__summary,
        .list-item__title {
            margin: 0 0 $gutter-small;
            font-size: 14px;
            @media (min-width: map-get($aui-breakpoint, 'medium')) {
                margin: 0 0 $gutter-medium;
                font-family: $aui-font-extended;
                font-size: 15px;
            }
        }
    }
}

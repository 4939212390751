/*
 * bastardised Audi UI styles
 */
em,
i {
    font-style: italic;
}

.aui-button {
    padding: 0.5em 0;
    &.aui-button--padded,
    &.aui-button--primary,
    &.aui-button--secondary {
        padding-left: 1.7em;
        padding-right: 1.7em;
        font-family: $aui-font-extended;
        font-weight: bold;
        // border-color: rgba(0,0,0,0.5);
    }
}

.aui-nav--bar {
    margin: 0 auto;
    font-size: 13.65px;
    .aui-nav__item {
        padding: 0 11px;
    }
    .aui-nav__item:first-child {
        padding-left: 0;
    }
    .aui-nav__item:last-child {
        padding-right: 0;
    }
    .aui-nav__action {
        color: $aui-color-black;
        &:hover {
            color: $aui-color-gray30;
        }
        &.is-active {
            font-weight: bold;
        }
    }
}

.aui-nav__indicator {
    height: 1px;
}

.is-animated .aui-nav__indicator {
    opacity: 0.5;
}

.aui-modal-dialog--layer {
    background-color: transparent;
    .aui-modal-dialog__body {
        max-width: 745px;
        width: auto;
        @media (min-width: map-get($aui-breakpoint, 'large')) {
            margin-left: -335px;
        }
    }
    .aui-modal-dialog__close {
        width: 24px;
        height: 24px;
        position: absolute;
        color: $aui-color-black;
        &:hover {
            color: $aui-color-gray30;
        }
        @media (min-width: map-get($aui-breakpoint, 'xsmall')) {
            width: 45px;
            height: 45px;
        }
    }
    .aui-modal-dialog__content {
        position: relative;
        background-color: $aui-color-white;
        color: $aui-color-body;
        padding: 2.5rem;
    }
}

.aui-modal-backdrop {
    background: rgba(0, 0, 0, 0.5);
}

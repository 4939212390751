#footer {
    font-family: $aui-font-extended;
    font-size: 12px;
    line-height: 18px;
    position: relative;
    width: 100%;
    overflow: hidden;
    bottom: auto;
    z-index: 100;

    .footer-expand-icon {
        display: none;
        position: absolute;
        top: 0;
        left: 50%;
        width: 40px;
        height: 40px;
    }

    &.fixed {
        position: fixed;
        bottom: -325px;
        transition: bottom 1s;

        .footer-expand-icon {
            display: block;
        }

        &:hover {
            bottom: 0;

            .footer-expand-icon {
                display: none;
            }
        }
    }

    .footer-wrapper {
        padding: 68px ($gutter-medium * 2);
        padding-bottom: 68px;
        border-top: 1px solid rgba(0,0,0,0.5);
    }

    .footer-title {
        font-weight: bold;
        margin: 0 0 12px;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        padding: 4px 0;
    }

    .phonenum {
        font-weight: bold;
        white-space: nowrap;
    }

    .social {
        text-align: right;
        white-space: nowrap;
        a {
            display: inline-block;
            margin-left: 10px;
        }
    }

    .footer-copy {
        padding: 50px 0 0;
        font-size: 85%;
        a,
        span {
            margin-right: 20px;
        }
    }
}

#mobile-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 100;
    background: $aui-color-white;
    border-top: 1px solid $aui-color-gray20;
    padding: 10px 0;
    text-align: center;

    .col-auto {
        width: 20%;
    }

    .mobile-footer-link {
        text-align: center;
        display: block;
        line-height: normal;
        font-size: 11px;
        cursor: pointer;

        &.active {
            color: $aui-color-red;
        }

        .label {
            display: block;
            white-space: nowrap;
        }
        @media (min-width: map-get($aui-breakpoint, 'xsmall')) {
            font-size: 14px;
        }
    }
}

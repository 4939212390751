.panel {
    position: fixed;
    width: 100%;
    background: $aui-color-white;
    z-index: 50;

    &.anchor-left {
        top: $header-height;
        bottom: 0;
        left: -100%;
        transition: left 0.2s ease-in-out;

        &.active {
            left: 0;
        }
    }

    &.anchor-bottom {
        left: 0;
        bottom: -100%;
        transition: bottom 0.2s ease-in-out;

        &.active {
            bottom: 0;
        }
    }

    .container-outer {
        height: 100%;
    }

    .vertical-gutter {
        padding: ($gutter-small * 2) 0 80px;
        height: 100%;
    }

    .panel-inner {
        height: 100%;
        overflow-y: auto;
    }

    .panel-content {
        position: relative;
    }

    &.has-close-button {
        .panel-content {
            padding-right: 36px;
        }

        .panel-close {
            position: absolute;
            top: ($gutter-small * 2);
            right: $gutter-small;
            z-index: 10;
        }
    }
    @media (min-width: map-get($grid-breakpoints, 'xsmall')) {
        .vertical-gutter {
            padding-bottom: 120px;
        }
    }
    @media (min-width: map-get($grid-breakpoints, 'small')) {
        &.anchor-left {
            top: $header-height-small;
        }

        &.has-close-button {
            .panel-content {
                padding-right: 50px;
            }

            .panel-close {
                right: ($gutter-small * 2);
            }
        }
    }
    @media (min-width: map-get($grid-breakpoints, 'medium')) {
        &.anchor-left {
            top: $header-height-medium;
        }

        .vertical-gutter {
            padding-top: ($gutter-medium * 2);
        }

        &.has-close-button {
            .panel-close {
                // top: ($header-height-medium + $gutter-medium);
                right: ($gutter-medium * 2);
            }
        }
    }
    @media (min-width: map-get($aui-breakpoint, 'large')) {
        transition: opacity 0.2s ease-in-out !important;
        opacity: 0;
        position: absolute;
        top: auto !important;
        bottom: auto !important;
        left: auto !important;
        height: 0;
        overflow: hidden;

        &.has-close-button {
            .panel-close {
                top: 20px;
            }
        }

        &.active {
            overflow: visible;
            opacity: 1;
        }

        .vertical-gutter {
            padding: 0 !important;
            height: 0;
        }

        .panel-inner {
            height: auto;
            background: $aui-color-white;
            border: 1px solid $aui-color-red;
            float: right;
            display: inline-block;
        }
    }
}

.panel--mainnav {
    font-family: $aui-font-extended;
    line-height: normal;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li {
        padding: 7px 0;
    }

    .categories {
        font-size: 15px;
    }

    .mainnav-links-medium {
        font-size: 11px;
    }

    .mainnav-links-small {
        font-size: 9px;
    }
}

.panel--search {
    @media (min-width: map-get($aui-breakpoint, 'large')) {
        .panel-inner {
            width: 100%;
            border-color: $aui-color-gray20;
            border-width: 0 0 1px;
            margin-top: 20px;
            padding: 0 0 ($gutter-medium * 2);
            max-height: 80vh;
        }
    }
}

.panel--filter {
    font-family: $aui-font-extended;

    .row {
        margin-right: -12px;
        margin-left: -12px;
    }

    .row > .col,
    .row > [class*="col-"] {
        padding-right: 6.5px;
        padding-left: 6.5px;
    }

    .container-outer {
        padding: 13px;
    }

    .panel-inner {
        width: 300px;
        margin-right: ($gutter-medium * 2);
    }

    .filter-button {
        background: transparent;
        border: none;
        box-shadow: none;
        outline: none;
        padding: 0;
        width: 100%;
        line-height: 1.15;
        text-align: center;
        cursor: pointer;

        &:hover {
            color: $aui-color-gray30;
        }

        &.active {
            color: $aui-color-red;
        }

        .label {
            display: block;
            white-space: nowrap;
        }
    }

    .main-filter .filter-button {
        font-size: 10px;

        .audiicon {
            width: 30px;
            height: 30px;
        }
    }

    .model-filter {
        margin-top: $gutter-medium;

        button {
            font-size: 12px;
            margin-right: 15px;
            padding-bottom: 0;

            &.active {
                color: $aui-color-red;
                border-bottom: 1px solid $aui-color-red;
            }
        }
    }
}

.panel--models {
    border-top: 1px solid $aui-color-gray20;

    .vertical-gutter {
        padding-top: ($gutter-small * 2);
        @media (min-width: map-get($grid-breakpoints, 'medium')) {
            padding-top: ($gutter-medium * 2);
        }
    }

    &.has-close-button .panel-close {
        top: 40px;
    }

    .panel-content {
        button {
            font-family: $aui-font-extended;
            font-size: 14px;
            margin-right: 20px;
            padding-bottom: 2px;

            &.active {
                color: $aui-color-red;
                border-bottom: 1px solid $aui-color-red;
            }
        }
    }
}
